<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M8 0L0 3.33333V4.66667H16V3.33333L8 0ZM0 5.33333V6H0.666667V14H15.3333V6H16V5.33333H13.3333V6H14V12.6667H12V6H12.6667V5.33333H10V6H10.6667V12.6667H8.66667V6H9.33333V5.33333H6.66667V6H7.33333V12.6667H5.33333V6H6V5.33333H3.33333V6H4V12.6667H2V6H2.66667V5.33333H0ZM0 14.6667V16H16V14.6667H0Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
